import React from 'react';
import styled from 'styled-components';
import SEO from 'components/seo';
import CorporateGovernanceLayout from 'layouts/corporate-governance-layout';
import { GoBackButton, MobileHeading } from 'components/misc';
import { media } from 'utils';

const Person = styled.div`
  display: flex;
  margin-bottom: 16px;

  ${media.tablet`
    flex-direction: column;
  `}
`;

const PersonInfo = styled.div`
  flex: 1;
  padding-right: 16px;

  ${media.tablet`
    padding: 0;
  `}
`;

const Name = styled.h3`
  font-size: 2.571rem;
  color: ${({ theme })=> theme.color.gray};
`;

const PersonDescription = styled.p`
  color: ${({ theme })=> theme.color.green};
`;

const PersonaSquad = ()=> {
  return (
    <CorporateGovernanceLayout>
      <SEO title="Zarząd" />
      <GoBackButton path="/investors" name="Dla akcjonariuszy" />
      <MobileHeading heading="Zarząd" />
      <Person>
        <PersonInfo>
          <Name>Bartosz Kubik</Name>
          <PersonDescription>
            Prezes Zarządu
          </PersonDescription>
        </PersonInfo>
      </Person>
      <Person>
        <PersonInfo>
          <Name>Barbara Łągiewka</Name>
          <PersonDescription>
            Wiceprezes Zarządu
          </PersonDescription>
        </PersonInfo>
      </Person>
      <Person>
        <PersonInfo>
          <Name>Elżbieta Matkowska</Name>
          <PersonDescription>
            Członek Zarządu
          </PersonDescription>
        </PersonInfo>
      </Person>
    </CorporateGovernanceLayout>
  );
};

export default PersonaSquad;
